.ProseMirror * {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.tiptap {
  > * + * {
    margin-top: 0.75em;
  }
  font-family: var(--ett-font-family);
  font-size: 14.8px;
}

.tiptap.--theme-dark {
  color-scheme: dark;
  color: var(--color-text-primary);
  padding: 0.7rem 0.5rem;
  background-color: var(--color-dark);
  border-radius: var(--ett-border-radius);
}

/*placeholder styling*/
.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tiptap.--theme-dark p.is-editor-empty:first-child::before {
  color: var(--color-text-primary-darker-2);
}

.tiptap .mention {
  border: 1px solid currentColor;
  border-radius: 0.4rem;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem;
  font-size: 13px;
}

.tiptap.tt-expression-input {
  min-height: 40px;
}


.tiptap.tt-expression-input:focus-visible {
  outline: none;
  box-shadow: none;
}
