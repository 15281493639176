:root {
  color-scheme: dark;
  --color-paper: #222B3E;
  --color-paper-alt: #283041;
  --color-paper-dark-2: #242A37;
  --color-panel-header-half: #1D2534;
  --color-paper-dark: #282C34;
  --color-panel-header: #181F2A;
  --color-table-header: #444964;
  --color-dark: #1C2531;
  --color-ssr: #FF6D6C;
  --color-positive: #1ED26F;
  --color-negative: #f75419;
  --color-border: #414141;
  --color-mui-error: #f44336;
  --color-text-primary: #e6e5e8;
  --color-text-primary-darker-1: #adb0bb;
  --color-text-primary-darker-2: #868a9a;
  --color-text-secondary: #8a85ff;
  --color-text-secondary-darker-1: #6131FF;
  --color-text-secondary-darker-2: #4037ff;
  --color-text-secondary-brighter-1: #9a95ff;
  --color-header-border: #7f7f7f;
  --color-toggle-button: #49515D;
  --color-toggle-button-hover: #353C4C;
  --color-hover-alpha: rgba(255, 255, 255, 0.04);
  --color-halt-background: rgba(252, 47, 43, .16);
  --ett-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  --ett-mono-font-family: "Roboto Mono", "Roboto", "Helvetica", "Arial", sans-serif;
  --ett-font-size: 12px;
  --scrollbarWidth: 10px;
  --scrollbarHeight: 4px;
  --scrollbarWidthSmall: 8px;
  --scrollbarBg: transparent;
  --scrollbarThumb: rgba(230, 229, 232, .7);
  --scrollbarThumbInactive: rgba(230, 229, 232, .4);
  --ett-container-spacing: 4px;
  --ett-border-radius: 4px;
  --mosaic-window-border-color: rgb(79,79,79);
}


.tv_chart,
.tv_chart iframe {
  background-color: #14151a !important;
}

.floating-icon-cont {
  height: 16px;
  width: 22px;
  flex-shrink: 0;
  flex-basis: 22px;
  position: relative;
  overflow: visible;
}

.floating-icon-cont.--small {
  width: 12px;
  flex-basis: 12px;
}

.expression-icon-list-item {
  font-size: 18px !important;
  color: var(--color-text-primary-darker-1);
  margin-left: -4px;
  margin-right: 1px;
}

.floating-icon-cont .expression-icon-list-item {
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.ett-mosaic-scrollbar::-webkit-scrollbar {
  width: var(--scrollbarWidthSmall);
  height: var(--scrollbarWidthSmall);
}

.modal-horizontal-scrollbar-sm::-webkit-scrollbar {
  width: var(--scrollbarWidthSmall);
  height: var(--scrollbarWidthSmall);
}

::-webkit-scrollbar {
  width: var(--scrollbarWidth);
  height: var(--scrollbarHeight);
}

::-webkit-scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: var(--scrollbarBg);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbarThumbInactive);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBg);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbarThumb);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
