.mosaic.mosaic-theme-ett {
  background: var(--color-dark);
  --highlight-color: var(--color-text-secondary);
  --highlight-color-opacity: rgba(138, 133, 255, .04);
  --mosaic-variant-yellow: #A59900;
}

.mosaic.mosaic-theme-ett .mosaic-tile {
  margin: var(--ett-container-spacing);
  border-radius: var(--ett-border-radius);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-body,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-body {
  border-radius: var(--ett-border-radius);
  background: var(--color-dark);
  font-family: var(--ett-font-family);
  /*border: 1px solid var(--mosaic-window-border-color); SEE INSIDE MosaicPanel.js*/
}

/* Target parent, since getting component state above each component would be a nightmare */
.mosaic.mosaic-theme-ett .mosaic-window-variant--yellow:has( > .mosaic-window-body) {
  border-color: var(--moasic-variant-yellow);
}

.mosaic.mosaic-theme-ett.mosaic-zero-state {
  background: pink;
  border-radius: var(--ett-border-radius);
}

.mosaic.mosaic-theme-ett.mosaic-zero-state .default-zero-state-icon {
  font-size: 120px;
}

.mosaic.mosaic-theme-ett .mosaic-split:hover {
  background: none;
}

.mosaic.mosaic-theme-ett .mosaic-split:hover .mosaic-split-line {
  box-shadow: 0 0 0 1px var(--highlight-color);
}

.mosaic.mosaic-blueprint-theme.mosaic-drop-target .drop-target-container .drop-target,
.mosaic.mosaic-theme-ett.mosaic-drop-target .drop-target-container .drop-target {
  background: var(--highlight-color-opacity);
  border: 2px solid var(--highlight-color);
  transition: opacity 100ms;
  border-radius: var(--ett-border-radius);
}

.mosaic.mosaic-theme-ett .mosaic-window,
.mosaic.mosaic-theme-ett .mosaic-preview {
  border-radius: var(--ett-border-radius);
  box-shadow: none;
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-toolbar,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-toolbar {
  /*box-shadow: 0 1px 1px;*/
  border-top-right-radius: var(--ett-border-radius);
  border-top-left-radius: var(--ett-border-radius);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-toolbar.draggable:hover,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-toolbar.draggable:hover {
  background: linear-gradient(to bottom,var(--color-paper),var(--color-paper-alt));
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-toolbar.draggable:hover .mosaic-window-title,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-toolbar.draggable:hover .mosaic-window-title {
  color: var(--color-text-primary);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-title,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-title {
  font-weight: 600;
  color: var(--color-text-primary);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-controls .separator,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-controls .separator {
  /*border-left: 1px solid;*/
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-controls .-button,
.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-controls .-button:before,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-controls .-button,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-controls .-button:before {
  color: var(--color-text-secondary);
}

.mosaic.mosaic-theme-ett .mosaic-window .default-preview-icon,
.mosaic.mosaic-theme-ett .mosaic-preview .default-preview-icon {
  font-size: 72px;
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-additional-actions-bar,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-additional-actions-bar {
  transition: height 250ms;
  /*box-shadow: 0 1px 1px;*/
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-additional-actions-bar .-button,
.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-additional-actions-bar .-button:before,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-additional-actions-bar .-button,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-additional-actions-bar .-button:before {
  color: var(--color-text-secondary);
}

.mosaic.mosaic-theme-ett .mosaic-window.additional-controls-open .mosaic-window-toolbar,
.mosaic.mosaic-theme-ett .mosaic-preview.additional-controls-open .mosaic-window-toolbar {
  /*box-shadow: 0 1px 0;*/
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-preview,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-preview {
  border: 1px solid var(--color-dark);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-preview h4,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-preview h4 {
  color: var(--color-text-primary);
}


